/** Global CSS goes here */
html,
body,
div#root {
  min-height: 100%;
  min-width: 640px;
}

/** Fix for date picker going under "items per page" select **/
.react-datepicker-popper {
  z-index: 1000 !important;
}
